import { ThemeProvider as EmotionThemeProvider } from '@emotion/react'
import React, { memo } from 'react'

import { MediaContextProvider, mediaStyle } from './media'

export type Theme = typeof theme

export const theme = {
  colors: {
    variants: {
      neutralLight1: '#FFFFFF',
      neutralLight2: '#F8F8EF',
      neutralLight3: '#F2F2E7',
      neutralLight4: '#83AF94',
      neutralDark1: '#000000',
      neutralDark2: '#393736',
      neutralDark3: '#262423',
      primaryLight: '#F5B6A3',
      danger: '#CC5858',
    },
  },
  fontFamily: {
    heading: "'ivypresto-display', serif",
    paragraph: "'adobe-garamond-pro', serif",
    sansSerif: "'termina', sans-serif",
  },
}

export const ThemeProvider = memo(function ThemeProvider({ children }: any) {
  return (
    <EmotionThemeProvider theme={theme}>
      <style type="text/css">{mediaStyle}</style>
      <MediaContextProvider disableDynamicMediaQueries>
        {children}
      </MediaContextProvider>
    </EmotionThemeProvider>
  )
})
